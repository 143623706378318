<template>
  <div id="app" class="outerBox" ref="outerDom" @scroll="getScrollTop">
<!--    <top-header></top-header>-->
    <div  v-if="hideBack == 1">
      <PcHeader style="width: 10%;margin-right:15px "></PcHeader>
      <router-view
          style="
        min-height: calc(100vh - 40px);
        /*max-width: 100%;*/
        width: 100%;
        overflow: hidden;
      "
      ></router-view>
    </div>
    <div v-else-if="hideBack == 2">
      <router-view
          style="
        min-height: 100vh;
        /*max-width: 100%;*/
        width: 100%;
        overflow: hidden;
      "
      >
      </router-view>
    </div>
<!--    <botton-tabbar></botton-tabbar>-->
    <div class="w100 fix Frlt">
      <img  style="height: 30px;width: 30px"  @click="goBack"  :src="require('./assets/pc_images/common/down.png')"/>
    </div>
  </div>
</template>

<script>
import "@/assets/style/common.css";
import PcHeader from "@/components/PcHeader";
// import TopHeader from "./components/TopHeader";
export default {
  components: { PcHeader},
  data() {
    return {
      scrollTop: 0, //默认距离顶部的距离
      isShow: false, //控制返回顶部按钮的显隐
      scrollTrigger: false, //默认初始值
      showPage: '',
    };
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      // let _r = this.$router.options.routes.find((r) => {
      //   return r.path == this.$route.path;
      // });
      // if (_r) {
      //   document.title = this.$t(_r.ext.title);
      // }
    },
  },
  computed: {
    hideBack() {
      return this.$route.meta.hideBack;
    },
  },
  // mounted() {
  //   if (this._isMobile()) {
  //     this.$router.push({ name: 'new' });
  //   } else {
  //     this.$router.push({ name: 'p_new' });
  //   }
  // },
  methods: {
    // _isMobile() {
    //   let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    //   return flag;
    // },
      // 返回顶部事件
      goBack() {
        let that = this; // 防止this指向问题
        // 防止频繁点击，故返回顶部后设置scrollTrigger为初始值
        if (that.scrollTrigger) {
          return;
        }
        // 获取当前距离顶部的距离
        let scrollTop = this.scrollTop;
        console.log(scrollTop);
        let steep = scrollTop / 2000;
        let timer = setInterval(() => {
          that.scrollTrigger = true;
          // 滚动的速度逐渐变快，第一次走2000/1，然后减去已走的距离，下一次用剩下的距离再减去步进值，步进值也是不断变化，这样速度会越来越快
          scrollTop -= steep;
          // 步进值不改变的话会匀速缓慢上滑，不断增加步进值上滑的距离增加，视觉效果速度变快
          steep += 20;
          if (scrollTop <= 0) {
            clearInterval(timer);
            that.scrollTrigger = false;
          }
          that.$refs.outerDom.scrollTop = scrollTop;
        }, 30);
      },
      // 监听滚动条
      getScrollTop(e) {
        let that = this; // 防止this指向问题
        // 设备一屏的高度
        let clientHeight =
            document.documentElement.clientHeight || document.body.clientHeight;
        let scrollTop = e.srcElement.scrollTop;
        this.scrollTop = scrollTop;
        // 判断距离顶部多少显示回到顶部图标
        if (scrollTop > clientHeight) {
          that.isShow = true;
        } else {
          that.isShow = false;
        }
      },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.fix{
  position: fixed;
  right: 50px;
  bottom:20px;
}
.outerBox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  padding:4px 6px;
}
.header {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 180px;
  z-index: 4;
}
.header {
  width: 148px;
}
.header-other {
  left: 0px;
  margin-left: 0px;
}
</style>
