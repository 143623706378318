import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
// import { i18n } from "@/utils/i18n";
// import store from '@/store'
// import { getToken } from '@/utils/auth'

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.transformRequest = [function () {
//     // let src = ''
//     // for (let item in data) {
//     //     src += encodeURIComponent(item) + '=' + encodeURIComponent(data[item]) + '&'
//     // }
//     // return src
// }]
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 150000
})


service.interceptors.request.use(
    config => {

        // if (store.getters.token) {
        //     config.headers['X-Token'] = getToken()
        // }
        config.headers['accept-language'] = localStorage.getItem('lang')?localStorage.getItem('lang'):"en"
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data

        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 1) {
            if (res.code === 30001) {
                // let message = res.info
                MessageBox.confirm(res.info, '导入表格错误', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'danger'
                }).then(() => {
                    location.reload()
                }).catch(() => {
                    location.reload()
                })
            } else {
                Message({
                    message: res.info || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                })
            }
            // return Promise.reject(new Error(res.info || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service


//
// import axios from 'axios'
// // import store from '@/store'
// import { Toast } from 'vant'
// // import { VueAxios } from './axios'
// // import Cookies from 'js-cookie';
// // import { ACCESS_TOKEN } from '@/store/mutation-types'
// // import { router } from '@/router'
//
// // 创建 axios 实例
// const service = axios.create({
//     baseURL: process.env.VUE_APP_BASE_API, // api base_url
//     timeout: 30000 // 请求超时时间
// })
//
// const err = (error) => {
//     if (error.response) {
//         const data = error.response.data
//         // const token = Cookies.get(ACCESS_TOKEN)
//         if (error.response.status === 404) {
//             Toast.fail('Fail')
//         }
//         if (error.response.status === 403) {
//             Toast.fail('Forbidden')
//         }
//         if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
//             Toast.fail('Unauthorized')
//             // if (token) {
//             //     store.dispatch('Logout').then(() => {
//             //         router.push({ name: 'login' })
//             //     })
//             // }
//         }
//     }
//     return Promise.reject(error)
// }
//
// // request interceptor
// service.interceptors.request.use(config => {
//     config.headers['api-name'] = "wap"
//     // const token = Cookies.get(ACCESS_TOKEN)
//     // if (token) {
//     //     config.headers['Api-Token'] = `${token}` // 让每个请求携带自定义 token 请根据实际情况自行修改
//     // }
//     // const lang = Cookies.get('language') ? Cookies.get('language') : 'en'
//     // if (lang) {
//     //     config.headers['Accept-Language'] = lang
//     // }
//     return config
// }, err)
//
// // response interceptor
// service.interceptors.response.use(() => {
//
//
// }, err)
//
// // const installer = {
// //     vm: {},
// //     // install(Vue) {
// //     //     Vue.use(VueAxios, service)
// //     // }
// // }
//
// export {
//     // installer as VueAxios,
//     service as axios
// }